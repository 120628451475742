<template>
  <el-dialog
    class="student-dialog"
    :title="`${preTitle}学生`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="860px"
    :before-close="cancel">
    <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
      <el-divider content-position="left"><h3>个人信息:</h3></el-divider>
      <el-row>
        <el-col :span="8">
          <el-form-item label="用户照片" prop="stuExamImg">
            <single-img-uploader img-fit="contain" :url.sync="formData.stuExamImg" height="164px" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="姓名" prop="stuName">
            <el-input v-model.trim="formData.stuName" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="录取编号" prop="examineNo">
            <el-input v-model.trim="formData.examineNo" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="性别" prop="sexType">
            <el-select v-model="formData.sexType" placeholder="请选择性别">
              <el-option
                v-for="{name,type} in $store.state.systemParam.sexType"
                :label="name" :value="type" :key="type" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="民族" prop="nation">
            <el-select v-model="formData.nation" placeholder="请选择民族">
              <el-option
                v-for="({name,id}) in nationList"
                :label="name"
                :value="name"
                :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="政治面貌" prop="political">
            <el-select v-model="formData.political" placeholder="请选择民族">
              <el-option v-for="({type}, index) in policyStatusList"
                         :label="type"
                         :value="type"
                         :key="index" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="身份证号" prop="idNumber">
            <el-input v-model.trim="formData.idNumber" maxlength="18" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系电话" prop="fixedPhone">
            <el-input v-model.trim="formData.fixedPhone" maxlength="11" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="高中名称" prop="middleSchool">
            <el-input v-model.trim="formData.middleSchool" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="学号" prop="stuNo">
            <el-input v-model.trim="formData.stuNo" maxlength="15" />
          </el-form-item>
        </el-col>
        <!--        <el-col :span='8'>-->
        <!--          <el-form-item label='校区' prop='campusId'>-->
        <!--            <el-select v-model='formData.campusId'>-->
        <!--              <el-option-->
        <!--                v-for='{campusName,id} in campusList'-->
        <!--                :label='campusName'-->
        <!--                :value='id'-->
        <!--                :key='id' />-->
        <!--            </el-select>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
      </el-row>
      <el-divider content-position="left"><h3>专业信息:</h3></el-divider>
      <el-row>
        <el-col :span="8">
          <el-form-item label="年级" prop="grade">
            <el-select v-model="formData.grade" @change="changeGrade">
              <el-option
                v-for="{paramValue} in $store.state.systemParam.grade"
                :label="paramValue" :value="paramValue" :key="paramValue" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="专业" prop="majorId">
            <el-select v-model="formData.majorId" @change="changeMajor" filterable>
              <el-option
                v-for="{majorName, id} in majorList"
                :label="majorName"
                :value="id"
                :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="院系" prop="collegeId">
            <el-select v-model="formData.collegeId" disabled>
              <el-option
                v-for="{collegeName,id} in collegeList"
                :label="collegeName"
                :value="id"
                :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="班级" prop="classesId">
            <el-select v-model="formData.classesId" :disabled="!classList.length">
              <el-option
                v-for="({className,id}) in classList"
                :label="className" :value="id" :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="职务">
            <el-input v-model.trim="formData.job" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left"><h3>联系信息:</h3></el-divider>
      <el-row>
        <el-col :span="8">
          <el-form-item label="联系人" prop="linkMan">
            <el-input v-model.trim="formData.linkMan" />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="联系人电话" prop="linkManPhone" label-width="100px">
            <el-input v-model.trim="formData.linkManPhone" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="父亲姓名" prop="fatherName">
            <el-input v-model.trim="formData.fatherName" />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="父亲电话" prop="fatherPhone" label-width="100px">
            <el-input v-model.trim="formData.fatherPhone" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="母亲姓名" prop="motherName">
            <el-input v-model.trim="formData.motherName" />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="母亲电话" prop="motherPhone" label-width="100px">
            <el-input v-model.trim="formData.motherPhone" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="邮编" prop="postCode">
            <el-input v-model.trim="formData.postCode" />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="详细地址" prop="addressDetails" label-width="100px">
            <el-input v-model.trim="formData.addressDetails" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left"><h3>录取信息:</h3></el-divider>
      <el-row>
        <el-col :span="8">
          <el-form-item label="考生号" prop="examineCode">
            <el-input v-model.trim="formData.examineCode" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="毕业类别" prop="graduateCategory">
            <el-input v-model.trim="formData.graduateCategory" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="录取阶段" prop="admissionStage">
            <el-input v-model.trim="formData.admissionStage" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="录取批次" prop="admissionBatch">
            <el-input v-model.trim="formData.admissionBatch" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="计划类别" prop="categoryName">
            <el-input v-model.trim="formData.categoryName" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="录取类别" prop="admissionCategory">
            <el-input v-model.trim="formData.admissionCategory" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="录取总分" prop="admissionMark">
            <el-input v-model.trim="formData.admissionMark" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="科类名称" prop="subjectKind">
            <el-input v-model.trim="formData.subjectKind" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left"><h3>专升本意向:</h3></el-divider>
      <el-row>
        <el-col :span="10">
          <el-form-item label="升本意向学校" label-width="100px">
            <el-input v-model="formData.bachelorIntentionSchool" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="意向专业">
            <el-input v-model="formData.bachelorIntentionMajor" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left"><h3>其他:</h3></el-divider>
      <el-form-item prop="payPassFlag" label-width="120px">
        <template #label>
          <tool-tips-group tips-content="若状态为“是”，则该学生无需缴费即可登录 智慧南职APP、智慧迎新等应用。">
            免缴费登录
          </tool-tips-group>
        </template>
        <el-radio-group v-model="formData.payPassFlag">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="remarks" label-width="120px">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('学生', addStudent)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addStudent, getStudentById } from '@/api/student'
import {
  policyStatusList,
  nationList
} from '@/views/pages/diagnosis-and-improvement/infoCollect/infoCollect/components/options.js'

import dialog from '@/vue/mixins/dialog'
import { validateTwoDecimalPlaces, validateNumber, validatePhone, validateIdNumber } from '@/utils/form-validate'
import campusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'

export default {
  name: 'studentDialog',
  mixins: [dialog, campusSchMajorClasses],
  data() {
    return {
      formData: {
        id: '',
        // 学生个人信息
        stuExamImg: '', // 用户照片
        userId: '', // 用户id
        stuNo: '', // 学号
        stuName: '', // 学生姓名
        examineNo: '', // 录取编号
        sexType: 1, // 性别（1——男，2——女）
        nation: '', // 民族
        political: '', // 政治面貌
        idNumber: '', // 身份证号
        fixedPhone: '', // 手机号码
        birthday: '', // 出生年月日
        userType: 2, // 用户类型1教职工,2学生,3后台管理员
        stuStatus: 0, // 学生状态（0——未注册，1——在读，2——毕业）
        middleSchool: '', // 中学名称
        // campusId: '', // 校区id
        // 录取信息
        examineCode: '', // 考生号
        graduateCategory: '', // 毕业类别
        admissionStage: '', // 录取阶段
        admissionBatch: '', // 录取批次
        categoryName: '', // 计划类别名称
        admissionCategory: '', // 录取类别
        admissionMark: '0', // 录取总分
        subjectKind: '', // 科类名称
        // 专业信息
        // campusId: '', // 校区
        collegeId: '', // 学院
        grade: '', // 年级
        majorId: '', // 专业
        classesId: '', // 班级
        job: '', // 职务
        // 专升本意向信息
        bachelorIntentionSchool: '', // 专升本意向学校
        bachelorIntentionMajor: '', // 专升本意向专业
        // 联系信息
        fatherName: '', // 父亲姓名
        fatherPhone: '', // 父亲电话
        motherName: '', // 母亲姓名
        motherPhone: '', // 母亲电话
        linkMan: '', // 联系人
        linkManPhone: '', // 联系电话
        postCode: '', // 邮票
        addressDetails: '', // 详细地址
        payPassFlag: 0, // 免缴费登录
        remarks: '' // 备注
      },
      policyStatusList, // 政治面貌
      nationList, // 民族
      rules: {
        stuName: [
          {
            required: true,
            message: '请输入学生名称',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        idNumber: [{
          required: true,
          message: '请输入身份证号',
          trigger: 'blur'
        }, {
          validator: validateIdNumber,
          trigger: 'blur'
        }],
        nation: [{
          required: true,
          message: '请选择民族',
          trigger: 'blur'
        }],
        political: [
          {
            required: true,
            message: '请选择政治面貌',
            trigger: 'blur'
          }
        ],
        examineNo: [
          {
            required: true,
            message: '请填写录取编号',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        grade: [{
          required: true,
          message: '请选择年级',
          trigger: 'blur'
        }],
        collegeId: [{
          required: true,
          message: '请选择院系',
          trigger: 'blur'
        }],
        majorId: [{
          required: true,
          message: '请选择专业',
          trigger: 'blur'
        }],
        fixedPhone: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'blur'
          },
          {
            validator: validatePhone,
            trigger: 'blur'
          }
        ],
        linkMan: [{
          required: true,
          message: '请填写联系人',
          trigger: 'blur'
        },
          this.$store.state.commonValidate.limit30WordsObj],
        linkManPhone: [
          {
            required: true,
            message: '请填写联系人手机号码',
            trigger: 'blur'
          },
          {
            validator: validatePhone,
            trigger: 'blur'
          }
        ],
        admissionMark: [{
          required: true,
          message: '请填写录取总分,最低为 0',
          trigger: 'blur'
        }, {
          validator: validateTwoDecimalPlaces,
          trigger: 'blur'
        }],
        postCode: {
          validator: validateNumber,
          trigger: 'blur'
        },
        subjectKind: this.$store.state.commonValidate.limit30WordsObj,
        bachelorIntentionSchool: this.$store.state.commonValidate.limit30WordsObj,
        bachelorIntentionMajor: this.$store.state.commonValidate.limit30WordsObj,
        examineCode: {
          validator: validateNumber,
          trigger: 'blur'
        },
        graduateCategory: this.$store.state.commonValidate.limit30WordsObj,
        admissionStage: this.$store.state.commonValidate.limit30WordsObj,
        admissionBatch: this.$store.state.commonValidate.limit30WordsObj,
        categoryName: this.$store.state.commonValidate.limit30WordsObj,
        admissionCategory: this.$store.state.commonValidate.limit30WordsObj
      }
    }
  },
  async mounted() {
    await Promise.all([
      this.getCollegeList(),
      this.getMajorList()
    ])
    // this.getCampusList()

    await this.$nextTick()
    if (this.id) {
      await this.getDataById(this.id, getStudentById)
      await this.getClassList({
        grade: this.formData.grade,
        majorId: this.formData.majorId
      })
    } else {
      this.setInitData()
    }
  },
  methods: {
    addStudent, // 保存api
    // 根据专业Id获取学院信息
    getMajorById(majorId) {
      this.$http.getMajorById(majorId).then((res) => {
        // this.formData.campusId = res.data.campusId
        this.formData.collegeId = res.data.collegeId
      })
    },
    // 选择年级
    changeGrade(grade) {
      this.formData.classesId = ''
      if ( !this.formData.majorId) return
      if (grade) {
        this.getClassList({
          grade: grade,
          majorId: this.formData.majorId
        })
        this.getMajorList({ grade: grade })
      }
    },
    // 选择专业
    changeMajor(majorId) {
      this.formData.collegeId = ''
      // this.formData.campusId = ''
      this.formData.classesId = ''
      this.getMajorById(majorId)
      this.getClassList({
        grade: this.formData.grade,
        majorId: majorId
      })
    }
  }
}
</script>
<style lang="scss">
.student-dialog {
  .el-dialog {
    margin-top: 20px !important;
  }
}
</style>
